import { Interface } from "./index";

export const initial_data: Interface[] = [
  // { _id: '1', name: 'Casa Familiar' },
  // { _id: '2', name: 'Apartamento Soacha' },
  // { _id: '3', name: 'Casa Ciudad Bolivar' },
]

export default initial_data

