import React from 'react';

import styles from './index.module.scss';

function LoaderV1Component() {

  return ( <div className={styles.loader}></div> );
}

export default LoaderV1Component;
