// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyDxBR8rlITQrrTZmbuqa3fq7E8jiXsKrm4",
  authDomain: "mis-gastos-hogar.firebaseapp.com",
  projectId: "mis-gastos-hogar",
  storageBucket: "mis-gastos-hogar.appspot.com",
  messagingSenderId: "822475997506",
  appId: "1:822475997506:web:f7586496e9f010f6f2c8db"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app)

export { app, auth }